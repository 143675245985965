import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import PageLayout from '@components/layout/PageLayout';
import PageHero from '@components/layout/PageHero';
import * as styles from '@css/modules/category.module.scss';
import locale from '@util/locale';
import { useMPEvent } from '@util/mixpanel';
import { Book } from 'react-bootstrap-icons';
import { ResourceData } from '@src/interfaces/ResourceData';
import { RichTextField } from '@src/interfaces/RichTextField';
import { Image } from '@src/interfaces/Image';
import { ResourceGrid } from '@components/ResourceGrid';
import {
  CategoryContentSection,
  ContentSection,
} from '@components/ContentSection';

import { richTextOptions } from '@util/richTextOptions';

export default function Category({
  data: {
    contentfulCategoryPage,
    allContentfulResourcePage,
    contentfulHomePage,
  },
  pageContext,
}: CategoryProps) {
  const {
    node_locale: nodeLocale,
    category,
    slug,
    title,
    indexHeader,
    contentSection,
    cta,
    sidebarCta,
    heroImage,
  } = contentfulCategoryPage;
  const { selector } = contentfulHomePage;

  const resources = allContentfulResourcePage.edges.map(({ node }) => node);
  const [feedback, toggleFeedback] = useState(false);
  const [helpful, setHelpful] = useState<'yes' | 'no'>();
  const [showMore, setShowMore] = useState(6);
  const [showMoreShort, setShowMoreShort] = useState(3);
  const { trackPageLoad, trackPageInteraction } = useMPEvent();

  const trackingData = {
    language: nodeLocale.toLowerCase() as 'en-ca' | 'fr-ca',
    url: `/${nodeLocale}/${slug}`,
    url_name: `category - ${category}`,
    element: 'resource selector',
  } as const;

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    await fetch(`/${nodeLocale}/${category}`, {
      method: 'POST',
      body: formData,
    });
    toggleFeedback(true);
  };

  useEffect(() => {
    trackPageLoad({
      language: nodeLocale.toLowerCase() as 'en-ca' | 'fr-ca',
      url: `/${nodeLocale}/${slug}`,
      url_name: `category - ${category}`,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    // @ts-expect-error
    <PageLayout
      translations={pageContext.translations}
      node_locale={nodeLocale}
      page={pageContext.pageName}
      pageTitle={title}
    >
      <PageHero data={{ header: title, nodeLocale, image: heroImage }} />

      <div className={styles.bg} />

      <Container className={`pt-5 pb-4 ${styles.contentParent}`}>
        <Row>
          {/* Side nav */}
          <Col xs={12} md={4}>
            <div className={`mb-5 ${styles.indexWrapper}`}>
              <h6 className={`mb-2 font-italic ${styles.indexHeader}`}>
                {indexHeader}
              </h6>
              {contentSection.map((section, index) => (
                <a
                  href={`#${section.sectionId}`}
                  className={`btn btn-link ${styles.indexParent}`}
                  key={`section-header-${index}`}
                >
                  {section.header}
                </a>
              ))}
            </div>

            <div className={`mb-5 ${styles.sidebarCta}`}>
              <div className="mr-4">
                <Book className={styles.sidebarCtaIcon} />
              </div>
              <div>{renderRichText(sidebarCta, richTextOptions)}</div>
            </div>
          </Col>

          <Col xs={12} md={8}>
            <div className="px-lg-4 px-xl-5">
              {/* Content Sections */}
              <Row className="mb-4">
                {contentSection.map((section, index) => (
                  <Col
                    key={index}
                    xs={12}
                    className={`${styles.content} content-section ${section.type} text-charcoal`}
                  >
                    <h2
                      className={`text-secondary text-bolder mb-4 ${styles.sectionHeader}`}
                      id={section.sectionId}
                    >
                      {section.header}
                    </h2>
                    {section.type === 'resources' ? (
                      // TODO: cleanup this ResourceGrid, perhaps having it inside ContentSection
                      <ResourceGrid
                        resources={resources}
                        pageName={pageContext.pageName}
                        locale={nodeLocale}
                        compact={true}
                        showMore={showMoreShort}
                        showMoreAmount={3}
                        setShowMore={(value: number) => {
                          setShowMoreShort(value);
                          trackPageInteraction({
                            ...trackingData,
                            action: 'load more',
                          });
                        }}
                        selector={selector}
                      />
                    ) : (
                      <ContentSection section={section} />
                    )}
                  </Col>
                ))}
              </Row>

              {/* Sign up CTA */}
              <div className={`p-3 p-md-4 mb-4 ${styles.contentCtaWrapper}`}>
                <h6 className={styles.contentCtaHeader}>{cta.header}</h6>
                <p className="my-4">{cta.copy}</p>
                <a
                  href={`${
                    process.env.GATSBY_GREENSPACE_URL
                  }/self_guided/signup/?lang=${nodeLocale.toLowerCase()}`}
                  className="btn btn-primary"
                >
                  {cta.buttonCopy}
                </a>
              </div>

              {/* Feedback */}
              <div className="bg-info rounded p-3 mb-4">
                {!feedback ? (
                  <form
                    className={styles.poll}
                    data-netlify="true"
                    name="category-feedback"
                    id="feedback-form"
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="form-name"
                      value="category-feedback"
                    />
                    <input type="hidden" name="helpful" value={helpful} />
                    <input type="hidden" name="category" value={category} />

                    <Row>
                      <Col
                        xs={12}
                        sm={6}
                        className="text-secondary text-bold align-self-center mb-3 mb-sm-0"
                      >
                        <h2 className="h6 mb-0">
                          {locale[nodeLocale].helpful}
                        </h2>
                      </Col>
                      <Col xs={3}>
                        <Button
                          type="button"
                          variant={
                            helpful === 'yes'
                              ? 'secondary'
                              : 'outline-secondary'
                          }
                          onClick={() => setHelpful('yes')}
                          block
                        >
                          {locale[nodeLocale].yes}
                        </Button>
                      </Col>
                      <Col xs={3}>
                        <Button
                          type="button"
                          variant={
                            helpful === 'no' ? 'secondary' : 'outline-secondary'
                          }
                          onClick={() => setHelpful('no')}
                          block
                        >
                          {locale[nodeLocale].no}
                        </Button>
                      </Col>
                    </Row>

                    {helpful ? (
                      <div className={styles.feedbackQualify}>
                        <div className={styles.qualifyHeader}>
                          {locale[nodeLocale].feedback[helpful].header}
                        </div>
                        <div className={styles.qualifyOptions}>
                          {locale[nodeLocale].feedback[helpful].options.map(
                            (option, i) => (
                              <Form.Check
                                className={styles.qualifyCheckbox}
                                id={`qualify-checkbox-${i}`}
                                name="qualify[]"
                                value={option}
                                key={`qualify-checkbox-${option}`}
                                type="checkbox"
                                label={option}
                              />
                            ),
                          )}
                        </div>
                        <div className={styles.submitWrapper}>
                          <Button type="submit" variant="secondary">
                            {locale[nodeLocale].sendFeedback}
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </form>
                ) : (
                  <Row className={`bg-info ${styles.poll}`}>
                    <Col
                      xs={12}
                      className="text-center text-secondary text-bold justify-content-center align-items-center align-self-center"
                    >
                      <h2 role="alert" className="mb-0">
                        {locale[nodeLocale].feedback_thankyou}
                      </h2>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Resources Grid */}
      <div className={`${styles.resources} pb-4`}>
        <Container>
          <h2 className="pt-5 mb-5 font-weight-bold">
            {locale[nodeLocale].tryThese}
          </h2>
          <ResourceGrid
            resources={resources}
            pageName={pageContext.pageName}
            locale={nodeLocale}
            showMore={showMore}
            setShowMore={(value: number) => {
              setShowMore(value);
              trackPageInteraction({
                ...trackingData,
                action: 'load more',
              });
            }}
            selector={selector}
          />
        </Container>
      </div>
    </PageLayout>
  );
}

interface PageContext {
  pageName: string;
  pageTitle: string;
  translations: {
    'en-CA': string;
    'fr-CA': string;
  };
}

interface CategoryProps {
  data: {
    contentfulCategoryPage: CategoryPage;
    allContentfulResourcePage: {
      edges: { node: ResourceData }[];
    };
    contentfulHomePage: any;
  };
  pageContext: PageContext;
}

interface CategoryPage {
  node_locale: 'en-CA' | 'fr-CA';
  category: any;
  slug: string;
  title: string;
  indexHeader: string;
  contentSection: CategoryContentSection[];
  cta: {
    header: string;
    copy: string;
    buttonCopy: string;
    buttonLink: string;
  };
  sidebarCta: RichTextField;
  heroImage: Image;
}

export const query = graphql`
  query($node_locale: String!, $category: String!, $id: String!) {
    contentfulHomePage(node_locale: { eq: $node_locale }) {
      selector {
        showMore
        empty {
          childMarkdownRemark {
            html
          }
        }
      }
    }

    contentfulCategoryPage(
      contentful_id: { eq: $id }
      node_locale: { eq: $node_locale }
    ) {
      node_locale
      category
      title
      slug
      indexHeader
      contentSection {
        ...ContentSection
      }
      cta {
        header
        copy
        buttonCopy
        buttonLink
      }
      sidebarCta {
        raw
      }
      heroImage {
        title
        fixed(height: 512 quality: 80) {
          ...GatsbyContentfulFixed
        }
        gatsbyImageData(
          height: 256
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }

    allContentfulResourcePage(
      filter: {
        node_locale: { eq: $node_locale }
        wellnessCategory: { eq: $category }
      }
      sort: { fields: featuredOrder, order: ASC }
    ) {
      edges {
        node {
          node_locale
          featuredOrder
          id: contentful_id
          uuid
          title
          slug
          tags
          externalLink
          canonicalUrl
          wellnessCategory
          age
          provider {
            name
            logo {
              title
              fixed(height: 55) {
                ...GatsbyContentfulFixed
              }
              gatsbyImageData(
                height: 55
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            branding {
              background
              text
              tag
            }
          }
          tileImage {
            title
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
            gatsbyImageData(
              height: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`;
